import React, { useRef, useState, useEffect } from 'react';
import './MobileView.css';
import { experimentalStyled as styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog  from '@mui/material/Dialog';
import {doc, getDoc, addDoc, query, collection, getDocs, where, orderBy } from 'firebase/firestore';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import * as UpChunk from '@mux/upchunk';
import CheckIcon from './Resources/CheckIcon.svg';
import { config } from '../Constants/Configurations';
import { firestore } from '../Constants/FirestoreConfig';
import VideoPreview from './VideoPreview';
import UploadStep0 from './UploadStep0';
import UploadStep1 from './UploadStep1';
import UploadStep2 from './UploadStep2';
import UploadStep3 from './UploadStep3';

const defaultTags = [
  '✈️ Travel Tip',
  '🏖️ Beach',
  '🍕 Food',
  '🏩 Hotel',
  '🌇 Views',
  '🏊 Pool',
  '🛏️ Room Tour',
  '✨ Luxury',
  '🍽️ Restaurants',
  '👨‍👩‍👧‍👦 Family Friendly',
  '🍷 Bar',
  '💆‍♀️ Spa',
  '🐶 Pet Friendly',
  '🏕️ Outdoor',
  '⛰️ Hiking',
  '🎳 Amenities',
  '🏋️ Fitness Center',
  '🎁 Gift Shop',
  '🎭 Entertainment',
];

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#C5C5C5',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#F8F8F8',
  },
}));
export default function MobileView() {
  const fileInput = useRef(null);
  const [uploaded, setUploaded] = useState(false);
  const [uploadedFile, setUploadedfile] = useState(null);
  const [progressModel, setProgressModel] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadStep, setUploadStep] = useState(0);
  const [contentDuration, setContentDuration] = useState(0);
  const videoRef = useRef(null);
  const [uploadDisableBtn, setUploadDisableBtn] = useState(true);
  const previewVideoRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [videoPreview, setVideoPreview] = useState();
  const [invalidSiteId,setInvalidSiteId] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [siteData, setSiteData]= useState();

// This function will fetch Tags data from from the 
  async function fetchTagsData(siteId) {

    const tagQueryRef = query(collection(firestore, 'Tags'), where('siteId', '==', siteId), where('tagFromUGC', '==', true), orderBy('createdDate', 'desc'));
    const tagsDoc = await getDocs(tagQueryRef);
    let listOfTags = []
    if(tagsDoc.docs.length ===0){
      // If there is no tags saved data show Default tags
      listOfTags = defaultTags;
    }else{
    const allTagData = tagsDoc.docs.map((item) => ({
      id: item.id,
      ...item.data(),
    }));
    listOfTags = allTagData;
  }

    setAllTags(listOfTags)
  }

  useEffect(() => {
    if(siteData && siteData?.id){
      fetchTagsData(siteData?.id);
    }
  }, [siteData]);

  

  /**
   * Handles the process of uploading media file to the server.
   * - Initiates the upload process by fetching the URL for uploading.
   * - Utilizes UpChunk library for chunked uploading with progress tracking.
   * - Updates the upload progress and triggers the next step upon successful upload.
   */
  const uploadMediaFile = async () => {
    // Display progress model during the upload process
    setProgressModel(true);

    // Fetch the URL for starting the public upload
    const uploadUrlResponse = await fetch(`${config.HIO_API_ENDPOINT}/brandcast/startPublicUpload`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cors: `${window.location.protocol}//${window.location.host}`,
      }),
    });

    // Parse the JSON response containing upload information
    const uploadUrlJson = await uploadUrlResponse.json();

    // Create an UpChunk upload instance for the selected file
    const upload = UpChunk.createUpload({
      endpoint: uploadUrlJson.uploadUrl,
      file: uploadedFile,
      chunkSize: 30720, // Uploads the file in ~30 MB chunks
    });

    // Flag to ensure 'progress' event doesn't trigger after success
    const successFired = false;

    // Event listener for tracking upload progress
    upload.on('progress', (progress) => {
      // Update the upload percentage in the state
      if (!successFired) {
        setUploadPercentage(progress.detail);
      }
    });

    // Event listener for handling upload errors
    upload.on('error', (err) => {
      console.error('💥 🙀', err.detail);
      // Handle error scenarios appropriately
    });

    // Event listener for handling successful upload
    upload.on('success', async (data) => {
      // Trigger the next step after a successful upload
      uploadData(uploadUrlJson.uploadId, 'Video');
    });

    // Event listener for handling upload attempt failures
    upload.on('attemptFailure', (failure) => {
      // Handle failure scenarios appropriately
    });
  };

  /**
   * Handles the uploading of metadata to the Firestore database after successful media upload.
   * - Constructs data object with relevant information for the uploaded content.
   * - Determines content type (Image/Video) and adjusts data accordingly.
   * - Adds the data to the 'PublicContent' collection in Firestore.
   * - Updates state to reflect the progress and success of the upload.
   * @param {string} url - The URL or identifier associated with the media upload.
   * @param {string} contentType - The type of content being uploaded (Image/Video).
   * @returns {DocumentReference} - Reference to the Firestore document containing the uploaded content data.
   */
  const uploadData = async (url, contentType) => {
    // Prepare data object with metadata for the uploaded content
    const dataToSave = {
      contentType,
      dateCreated: new Date(),
      title: uploadedFile?.name || '',
      siteId: siteData.id,
      uploadedFileName: uploadedFile?.name != null ? uploadedFile.name : '',
      fromUGC: true,
      archive: false,
      totalLength: 0,
      contentDuration,
      videoHeight: contentHeight,
      videoWidth: contentWidth,
      tag: selectedFilters,
      email,
      first_name: firstname,
      last_name: lastname
    };

    // Adjust data for Image content type
    if (contentType === 'Image') {
      dataToSave.image = url;
      dataToSave.imageName = uploadedFile?.name || '';
      dataToSave.imagewebp = '';
      dataToSave.uploaded = true;
    }

    // Adjust data for Video content type
    if (contentType === 'Video') {
      dataToSave.muxUploadId = url;
    }

    // Add the data to the 'PublicContent' collection in Firestore
    const contentLibraryRef = await addDoc(collection(firestore, 'PublicContent'), dataToSave);

    // Update state to reflect successful upload
    setProgressModel(false);
    setUploaded(true);

    // Return the reference to the Firestore document
    return contentLibraryRef;
  };

  /**
   * Handles the selection of a media file for upload and extracts metadata.
   * - Listens for changes in the selected file and calculates content duration, width, and height.
   * - Sets the state with metadata and progresses to the next step in the upload process.
   * @param {Event} event - The file input change event containing the selected file.
   */
  const handleFileUpload = async (event) => {
    // Get the selected file from the event
    const fileUploaded = event.target.files[0];

    // Create a URL for the selected file
    const url = URL.createObjectURL(fileUploaded);

    // Create a temporary video element to extract metadata
    const videoElForSize = document.createElement('video');
    videoElForSize.src = url;

    // Listen for metadata loaded event to calculate duration, width, and height
    videoElForSize.addEventListener('loadedmetadata', () => {
      setContentDuration(videoElForSize.duration);
      setContentWidth(videoElForSize.videoWidth);
      setContentHeight(videoElForSize.videoHeight);
    });

    // Set the state with metadata and progress to the next step
    setUploadedfile(fileUploaded);
    setUploadStep(1);
  };

  const handleUploadButton = async () => {
    await uploadMediaFile();
  };

  const handleUploadButtonClick = () => {
    fileInput.current.click(); // Trigger the file input
  };

  const handleEmail = (value) => {
    const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(value)) {
      setUploadDisableBtn(false);
    } else {
      setUploadDisableBtn(true);
    }
    setEmail(value);
  };

  const getSiteIdFromURL = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const siteIdFromParam = urlParams.get('siteId');
    if (siteIdFromParam != null) {
      let siteId = siteIdFromParam;
      const siteMetaDataRef = doc(firestore, 'siteMetaData', siteId);
      const siteMetaDataRefDoc = await getDoc(siteMetaDataRef);
      setSiteData({...siteMetaDataRefDoc.data()} , siteId);
      setInvalidSiteId(false);
    } else {
      setInvalidSiteId(true);
    }
  };

  const handlePlay = (value) => {
    setVideoPreview(value);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  useEffect(() => {
    getSiteIdFromURL();
  }, []);

  return (
    <>
      {invalidSiteId
      && (
        <div>
          Invalid site id
        </div>
      )}
      {!invalidSiteId
      && (
        <>
        <div className="mobile-view-main-div">
          {videoPreview
            ? (
              <VideoPreview
                videoPreview={videoPreview}
                setVideoPreview={setVideoPreview}
              />
            )
            : !uploaded && (
              <>
                {uploadStep === 0
                  && (
                    <UploadStep0
                    siteData={siteData}
                      handlePlay={handlePlay}
                      handleUploadButtonClick={handleUploadButtonClick}
                      handleFileUpload={handleFileUpload}
                      fileInput={fileInput}
                    />

                  )}
                {uploadStep === 1 && (
                  <UploadStep1 
                  setUploadStep={setUploadStep} 
                  uploadedFile={uploadedFile} 
                  previewVideoRef={previewVideoRef} />
                )}
                {uploadStep === 2 && (
                  <UploadStep2 
                  setUploadStep={setUploadStep} 
                  selectedFilters={selectedFilters} 
                  listOfTags =  {allTags}
                  setSelectedFilters={setSelectedFilters} />
                )}
                {uploadStep === 3 && (
                  <UploadStep3 
                  setUploadStep={setUploadStep} 
                  firstname={firstname} 
                  lastname={lastname} 
                  email={email} 
                  setFirstName={setFirstName} 
                  setLastName={setLastName} 
                  handleEmail={handleEmail} 
                  uploadDisableBtn={uploadDisableBtn} 
                  handleUploadButton={handleUploadButton} />
                )}
              </>
            )}

          {
            uploaded && (
              <div className="upload-successful-div">
                <img src={CheckIcon} alt="check-icon" height="67" width="67" />
                <span className="div-2-span-2">Submit Successful</span>
                <span className="div-2-span-3" style={{ textAlign: 'center' }}>
                  Thank you for submiting your content with us!
                  <br />
                  Please note that the content you’ve submitted may
                  <br />
                  be used on our website or social media.

                </span>
                <Button
                  className="upload-btn"
                  style={{ marginTop: '60%' }}
                  onClick={() => {
                    setUploadedfile(null);
                    setSelectedFilters([]);
                    setUploadStep(0);
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setUploadDisableBtn(true);
                    setUploaded(false);
                    setUploadPercentage(0);
                  }}

                >
                  Add Another Video
                </Button>
              </div>
            )
          }
        </div>
        <Dialog
          open={progressModel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="progress-model"
        >
          <div className="progress-model-div">
            <span className="progress-model-div-span">Preparing to Submit</span>
            <BorderLinearProgress variant="determinate" value={uploadPercentage} style={{ width: '100%' }} />
          </div>
        </Dialog>
        </>   
      )}
    </>
  );
}
