const production = {
  REACT_APP_FIREBASE_APP_API_KEY: 'AIzaSyAKKyalmy6QcnjJMQzOoZvpvZEYZ5Ica_w',
  REACT_APP_FIREBASE_APP_AUTHDOMAIN: 'auth.hiosocial.com',
  REACT_APP_FIREBASE_APP_BASEURL: 'https://hovr-production-default-rtdb.firebaseio.com',
  REACT_APP_FIREBASE_APP_PROJECT_ID: 'hovr-production',
  REACT_APP_FIREBASE_APP_STORAGEBUCKET: 'hovr-production.appspot.com',
  REACT_APP_FIREBASE_APP_MESSAGING_SENDER_ID: '897240009968',
  REACT_APP_FIREBASE_APP_APP_ID: '1:897240009968:web:42d049aa49152a01f94635',
  REACT_APP_FIREBASE_APP_MEASUREMENT_ID: 'G-1GYR7T4LF2',
  REACT_APP_WEB_LIVE_COLLECTION: 'Live_Members',
  REACT_APP_SENDBIRD_ID: 'E2BC0F39-CABF-4EC0-81C-ECC8371D9DF1',
  REACT_APP_STREAM_IO_API_KEY: 'rcehmathv2cc',
  REACT_APP_SITE_ID: '606OilBMIhaUBqArHF56', // 'NNEySDeAAatfI53eamfx',
  REACT_APP_IMAGE_URL: 'https://contap.s3.amazonaws.com/thumbnails/',
  REACT_APP_STREAM_ENV: 'prod_',
  REACT_APP_TEST_CSS: false,
  REACT_APP_STYLE_SHEET_URL: 'https://plugin.hiosocial.com/main.css',
  ALGOLIA_APP_ID: 'HFKBW8BUA5',
  ALGOLIA_API_KEY: '88f408e604bc56201d70851158386378',
  ALGOLIA_INDEX_NAME: 'users_search_production',
  HIO_API_ENDPOINT_NO_API: 'https://us-central1-hovr-production.cloudfunctions.net',
  HIO_API_ENDPOINT: 'https://us-central1-hovr-production.cloudfunctions.net/api',
  HIO_API_ENDPOINT_HIGH_MEMORY: 'https://us-central1-hovr-production.cloudfunctions.net/hmapi',
  HIDE_BLOCK_USERS: true,
  RESOURCES_USE_LOCAL: false,
  RESOURCES_CDN_BASE_URL: 'https://d3dzh1vbh3wbiy.cloudfront.net',
  HOVR_RESOURCES_CDN_BASE_URL: 'https://script.gethovr.com',
  CODE_SNIPPET_URL: 'https://plugin.hiosocial.com/bundle.js',
  OLD_CODE_SNIPPET_URL: 'https://plugin.hiosocial.com/bundle.js',
  NEW_CODE_SNIPPET_URL: 'https://script.gethovr.com/bundle.js',
  SOCIAL_API_TIKTOK_CLIENTKEY: 'awmvdc6sa8rvti33',
  SOCIAL_API_VIMEO_CLIENTKEY: 'e032de8af58c54310b02a79db61dadf5042d71b7',
  SOCIAL_API_SPOTIFY_CLIENTKEY: '4b057324f55c4c2482547390775dd0b1',
  SOCIAL_API_YOUTUBE_CLIENTKEY: '897240009968-cg6qo8u26h0m9cu5l3cho4v74hbu7qf0.apps.googleusercontent.com',
  IS_TESTING: false, // don't turn this value off ever
  VALIDATE_URLS: false,
  REACT_APP_FIREBASE_JWT: 'https://us-central1-hovr-production.cloudfunctions.net/api/auth/verifyandreturntoken',
  LOCAL_URL: 'https://app.gethovr.com',
  SHOPIFY_BILLING_URL: 'https://us-central1-hovr-production.cloudfunctions.net/handleShopifyBilling-api',
  SHOPIFY_APP_CLIENT_ID: '601fb39bf99828c223093a68ef489f78',
  SHOPIFY_APP_CLIENT_SECRET: '3f70509ccd254e21047027c6a0fe9943',
};
const development = {
  REACT_APP_FIREBASE_APP_API_KEY: 'AIzaSyBW_5ArzjqBBcDvUijXxE4x-HvcfMDNViM',
  REACT_APP_FIREBASE_APP_AUTHDOMAIN: 'devauth.hiosocial.com',
  REACT_APP_FIREBASE_APP_BASEURL: 'https://hovr-dev.firebaseio.com',
  REACT_APP_FIREBASE_APP_PROJECT_ID: 'hovr-dev',
  REACT_APP_FIREBASE_APP_STORAGEBUCKET: 'hovr-dev.appspot.com',
  REACT_APP_FIREBASE_APP_MESSAGING_SENDER_ID: '583701961921',
  REACT_APP_FIREBASE_APP_APP_ID: '1:583701961921:web:87af8518541e95b0274840',
  REACT_APP_FIREBASE_APP_MEASUREMENT_ID: 'G-4M5YZ5PS7G',
  REACT_APP_WEB_LIVE_COLLECTION: 'Live_Members',
  REACT_APP_SENDBIRD_ID: 'A4050AAB-499F-4EAB-9A0A-2CB085DEA803',
  REACT_APP_STREAM_IO_API_KEY: '89z6qx53mv46',
  REACT_APP_SITE_ID: '510', // '633', // '1317', // '452',
  REACT_APP_IMAGE_URL: 'https://dev-contap.s3.amazonaws.com/thumbnails/',
  REACT_APP_STREAM_ENV: 'dev_',
  REACT_APP_TEST_CSS: false,
  REACT_APP_STYLE_SHEET_URL: 'https://dev-plugin.hiosocial.com/main.css',
  ALGOLIA_APP_ID: 'HFKBW8BUA5',
  ALGOLIA_API_KEY: '88f408e604bc56201d70851158386378',
  ALGOLIA_INDEX_NAME: 'users_search',
  HIO_API_ENDPOINT_NO_API: 'https://us-central1-hovr-dev.cloudfunctions.net',
  HIO_API_ENDPOINT: 'https://us-central1-hovr-dev.cloudfunctions.net/api',
  HIO_API_ENDPOINT_HIGH_MEMORY: 'https://us-central1-hovr-dev.cloudfunctions.net/hmapi',
  HIDE_BLOCK_USERS: true,
  RESOURCES_USE_LOCAL: false,
  RESOURCES_CDN_BASE_URL: 'https://d20u85x491bz6g.cloudfront.net',
  HOVR_RESOURCES_CDN_BASE_URL: 'https://scriptdev.gethovr.com',
  OLD_CODE_SNIPPET_URL: 'https://dev-plugin.hiosocial.com/bundle.js',
  NEW_CODE_SNIPPET_URL: 'https://scriptdev.gethovr.com/bundle.js',
  SOCIAL_API_TIKTOK_CLIENTKEY: 'awmvdc6sa8rvti33',
  SOCIAL_API_VIMEO_CLIENTKEY: 'e032de8af58c54310b02a79db61dadf5042d71b7',
  SOCIAL_API_SPOTIFY_CLIENTKEY: '4b057324f55c4c2482547390775dd0b1',
  SOCIAL_API_YOUTUBE_CLIENTKEY: '583701961921-gmk1aqi3eb5r4n18e1ituncb1imqh7aa.apps.googleusercontent.com',
  IS_TESTING: true,
  VALIDATE_URLS: true,
  REACT_APP_FIREBASE_JWT: 'https://us-central1-hovr-dev.cloudfunctions.net/api/auth/verifyandreturntoken',
  LOCAL_URL: 'https://devapp.gethovr.com',
  SHOPIFY_BILLING_URL: 'https://us-central1-hovr-dev.cloudfunctions.net/handleShopifyBilling-api',
  SHOPIFY_APP_CLIENT_ID: '4bb68c647a520c3e8518abe6e73c6f66',
  SHOPIFY_APP_CLIENT_SECRET: '8962edce625bd9191dcea8915cfad4dd',
};
// Possible env node environments values are production, development & qa.
export const config = process.env.NODE_ENV === 'production' ? production : development;
export default config;
