import { Button, MobileStepper } from '@mui/material';
import React from 'react';
import WhiteBackArrow from './Resources/WhiteBackArrow.svg';
import './MobileView.css';

function UploadStep1({ 
    setUploadStep, 
    uploadedFile, 
    previewVideoRef 
}) {
  return (
    <div className="step1-div">
      <div style={{ display: 'flex', gap: '10px', cursor: 'pointer' }} onClick={() => setUploadStep(0)}>
        <img src={WhiteBackArrow} alt="back-arrow" />
        <span className="back-btn" style={{ color: '#FFFFFF' }}>Back</span>
      </div>
      <div className="playerStyle">
        <video className="h-full" width="100%" height="100%" controls autoPlay ref={previewVideoRef}>
          <source src={URL.createObjectURL(uploadedFile)} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="step1-bottom-div">
        <Button className="video-confirm-btn" onClick={() => setUploadStep(2)}>Confirm Video</Button>
        <MobileStepper
          variant="dots"
          steps={3}
          position="static"
          activeStep={0}
          sx={{ maxWidth: 400, flexGrow: 1 }}
          className="mobile-stepper"
        />
      </div>
    </div>
  );
}

export default UploadStep1;
