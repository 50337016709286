import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { config } from './Configurations';

export const firebaseApp = initializeApp({
  apiKey: config.REACT_APP_FIREBASE_APP_API_KEY,
  authDomain: config.REACT_APP_FIREBASE_APP_AUTHDOMAIN,
  databaseURL: config.REACT_APP_FIREBASE_APP_BASEURL,
  projectId: config.REACT_APP_FIREBASE_APP_PROJECT_ID,
  storageBucket: config.REACT_APP_FIREBASE_APP_STORAGEBUCKET,
  messagingSenderId: config.REACT_APP_FIREBASE_APP_MESSAGING_SENDER_ID,
  appId: config.REACT_APP_FIREBASE_APP_APP_ID,
  measurementId: config.REACT_APP_FIREBASE_APP_MEASUREMENT_ID,
});

export const authentication = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
