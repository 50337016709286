import { Button } from '@mui/material'
import React from 'react';
import MuxPlayer from "@mux/mux-player-react"; 


function VideoPreview({ videoPreview, setVideoPreview }) {
    return (
        <div className="preview-slide">
            <MuxPlayer
                streamType="on-demand"
                playbackId={videoPreview}
                autoplay
                loop
            />
            <Button
                className="preview-slide-close-btn"
                onClick={() => setVideoPreview()}
            >
                Close
            </Button>
        </div>
    )
}

export default VideoPreview