import React from 'react';


export default function NoTagsSelectedModal({setShowNoTagWarning, setUploadStep}) {
  return (
    <div className="fixed top-0 left-0 h-full w-full bg-black/[.35] flex justify-center items-center">
      <div className="w-full box-border mx-[26px] rounded-lg bg-[#222731] shadow-[0_3px_4px_0px_rgba(196,196,196,0.8)] p-4">
        <div className="font-['Inter'] text-[13px] font-semibold text-white mb-[20px] text-left">
          Are you sure you want to continue without adding a tag?
        </div>
        <div>
          <button
            className="rounded-sm border-2 border-white border-solid font-['Inter'] text-[13px] font-semibold text-white py-[5px] px-[11px] mr-[11px]"
            onClick={() => setShowNoTagWarning(false)}
          >
            Cancel
          </button>
          <button
            className="rounded-sm bg-white font-['Inter'] text-[13px] font-semibold py-[7px] px-[11px]"
            onClick={() => setUploadStep(3)}
          >
            Continue without tags
          </button>
        </div>
      </div>
    </div>
  )
}